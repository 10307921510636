<template>
  <div class="col-md-7 mb-3 relative" ref="thedonor" :class="{focusDonor: donateOnBehalf}">
    <!--ANONYMOUS-->
    <CardPlain >
      <div class="row mx-0 justify-content-center ">
        <div class="col-12 letter_spacing green-text-dark font13 bold mb-3 text-center ">
          DONATING AS:
        </div>
        <div class="col-6 mb-3">
          <TextField
            type="text"
            label="First Name:"
            required="required"
            placeholder="First Name"
            v-model="firstNameValue"
            id="firstName"
            color="gold"
            :errors="firstNameErrors"
            :hasErrors="firstNameErrors.length"
          />
        </div>
        <div class="col-6 mb-3">
          <TextField
            type="text"
            label="Last Name:"
            required="required"
            placeholder="Last Name"
            v-model="lastNameValue"
            id="lastName"
            color="gold"
            :errors="lastNameErrors"
            :hasErrors="lastNameErrors.length"
          />
        </div>
        <div class="col-12 mb-3">
          <TextField
            type="email"
            label="Email Address:"
            required="required"
            placeholder="email@mail.com"
            v-model="emailValue"
            id="email"
            color="gold"
            :errors="emailErrors"
            :hasErrors="emailErrors.length"
          />
        </div>
        <div class="col-12 mb-3">
          <TextField
            type="text"
            label="Mobile/Contact No.:"
            required="required"
            placeholder="Mobile"
            v-model="mobileValue"
            id="mobileanon"
            color="gold"
            :errors="mobileErrors"
            :hasErrors="mobileErrors.length"
          />
        </div>
        <div class="col-12 mb-3">
          <TextField
            type="text"
            label="Address Line 1:"
            required="required"
            placeholder="Address Line 1"
            v-model="addressLine1Value"
            id="addressLine1"
            color="gold"
            :errors="addressLine1Errors"
            :hasErrors="addressLine1Errors.length"
          />
        </div>
        <div class="col-12 mb-3">
          <TextField
            type="text"
            label="Town/City:"
            required="required"
            placeholder="Town/City"
            v-model="townValue"
            id="town"
            color="gold"
            :errors="townErrors"
            :hasErrors="townErrors.length"
          />
        </div>
        <div class="col-6 mb-3">
          <TextField
            type="text"
            label="Postal Code:"
            placeholder="Postal Code"
            v-model="postalCodeValue"
            id="postalCode"
            color="gold"
          />
        </div>
        <div class="col-6 mb-3">
          <SelectAll
            v-model="countryValue"
            id="country"
            :options="countries"
            placeholder="Country"
            displayValue="description"
            color="gold"
            label="Country"
            :isSearch="true"
          />
        </div>
        <div class="col-11 py-3 mt-3 border-top"></div>
        <div class="col-12 col-md-11 bold green-text-dark mb-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 col-md-auto p-0 text-center">
              <div class="noprofile_img m-auto white-text">
                <IconNoUser size="sizefull" />
              </div>
            </div>
            <div class="col-12 col-md text-center text-md-start">
              <div class="row mx-0" >
                <div class="col-12 p-0 bold green-text font19">
                  Guest Donation
                </div>
                <div class="col-12 p-0  medium green-text-light font10">
                  This Donation will be made Anonymously
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-11 my-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 text-center font13">
              If you would like to make this donation under your donor profile click below:
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <Button color="smallish" class="" size="small" btnText="Login/Register as a Donor" icon="arrow" @buttonClicked="$emit('showLogin')">
            <IconArrowForward color="white" />
          </Button>
        </div>
      </div>
    </CardPlain>
    <!--END ANONYMOUS-->
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconNoUser: defineAsyncComponent(() => import('@/components/icons/IconNoUser2.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue'))
  },
  name: 'Checkout Anonymous Donor',
  props: ['donateOnBehalf', 'firstName', 'lastName', 'email', 'mobile', 'addressLine1', 'town', 'postalCode', 'country', 'firstNameErrors', 'lastNameErrors', 'emailErrors', 'mobileErrors', 'addressLine1Errors', 'townErrors'],
  emits: ['update:firstName', 'update:lastName', 'update:email', 'update:mobile', 'update:addressLine1', 'update:town', 'update:postalCode', 'update:country', 'showLogin'],
  computed: {
    ...mapGetters([
      'countries'
    ]),
    firstNameValue: {
      get () {
        return this.firstName
      },
      set (val) {
        this.$emit('update:firstName', val)
      }
    },
    lastNameValue: {
      get () {
        return this.lastName
      },
      set (val) {
        this.$emit('update:lastName', val)
      }
    },
    emailValue: {
      get () {
        return this.email
      },
      set (val) {
        this.$emit('update:email', val)
      }
    },
    mobileValue: {
      get () {
        return this.mobile
      },
      set (val) {
        this.$emit('update:mobile', val)
      }
    },
    addressLine1Value: {
      get () {
        return this.addressLine1
      },
      set (val) {
        this.$emit('update:addressLine1', val)
      }
    },
    townValue: {
      get () {
        return this.town
      },
      set (val) {
        this.$emit('update:town', val)
      }
    },
    postalCodeValue: {
      get () {
        return this.postalCode
      },
      set (val) {
        this.$emit('update:postalCode', val)
      }
    },
    countryValue: {
      get () {
        return this.country
      },
      set (val) {
        this.$emit('update:country', val)
      }
    }
  }
}
</script>

<style scoped>

.noprofile_img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  position: relative;
  border: 2px solid var(--green-color-dark);
  background-color: var(--green-color-dark);
  padding: 12px;
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.noprofile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.focusDonor {
  animation: fadeAndZoom;
  animation-duration: 2s;
  animation-timing-function: ease;
}
@keyframes fadeAndZoom {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
